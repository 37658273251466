/** 监控日报详情 */
<template>
  <div class="daily-details">
    <section>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/personal/daily' }">监控日报</el-breadcrumb-item>
        <el-breadcrumb-item>日报详情</el-breadcrumb-item>
      </el-breadcrumb>

      <AssetsTable class="mt-20" :data="listData.data" :labelData="listData.label">
        <template v-slot:monitor_name="{scope}">
          <el-button type="text" class="pa-0" @click="handleChange(scope.row.id)">{{scope.row.monitor_name}}</el-button>
        </template>
      </AssetsTable>
    </section>
    <el-collapse v-if="listData.data" v-model="activeName" accordion @change="handleChange"
      class="mt-20 back-fff pa-16">
      <el-collapse-item v-for="(item,index) in listData.data" :key="index" :title="item.monitor_name" :name="item.id">
        <AssetsTable :maxHeight="(conData.data && conData.data.length>6) ? 500 : null" :data="conData.data"
          :labelData="conData.label">
          <template v-slot:name="{scope}">
            <router-link v-if="scope.row.type==3" target="_blank"
              :to="{path:'/enterprise-details',query:{qn:scope.row.code_id}}">
              <el-button type="text" class="pa-0">{{scope.row.name}}（{{scope.row.identificate_num}}）</el-button>
            </router-link>
            <router-link v-if="scope.row.type==1" target="_blank"
              :to="{path:'/patent-details',query:{pid:scope.row.code_id}}">
              <el-button type="text" class="pa-0">{{scope.row.name}}（{{scope.row.identificate_num}}）</el-button>
            </router-link>
            <router-link v-if="scope.row.type==2" target="_blank"
              :to="{path:'/trademark-details',query:{tid:scope.row.code_id}}">
              <el-button type="text" class="pa-0">{{scope.row.name}}（{{scope.row.identificate_num}}）</el-button>
            </router-link>
          </template>
        </AssetsTable>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import AssetsTable from '@/components/Assets/table.vue'
import Page from '@/components/Page.vue'
import { getDailyDetailsList, getDailyDetailsCon } from "@/api/personal";
export default {
  name: 'DailyDetails',
  components: {
    AssetsTable,
    Page
  },
  data () {
    return {
      activeName: null,
      listData: {
        label: [
          { label: '监控名称', value: 'monitor_name', isSlot: true, width: '300px' },
          { label: '高风险', value: "4" },
          { label: '提示', value: "3" },
          { label: '警示', value: "2" },
          { label: '利好', value: "1" },
        ],
        data: null,
        pageTotal: 0
      },
      conData: {
        label: [
          { label: '风险等级', value: 'my_high_risk', width: '100px' },
          { label: '知产名称', value: 'name', isSlot: true },
          { label: '知产类型', value: 'property_type', width: '120px' },
          { label: '动态内容', value: 'content' },
        ],
        data: null
      }
    }
  },
  mounted () {
    this.getDailyDetailsList()
  },
  methods: {
    // 监控日报详情--列表
    async getDailyDetailsList () {
      try {
        let { data, status } = await getDailyDetailsList(this.$route.query)
        if (status == 200) {
          data.forEach(item => {
            item['1'] = item.dailyDetails['1'] || 0
            item['2'] = item.dailyDetails['2'] || 0
            item['3'] = item.dailyDetails['3'] || 0
            item['4'] = item.dailyDetails['4'] || 0
          })
          this.listData.data = data
        }
      } catch (error) {
        return false
      }
    },
    // 监控日报详情--主体
    async getDailyDetailsCon (id) {
      try {
        let { data, status } = await getDailyDetailsCon(id)
        if (status == 200) {
          data.forEach(item => {
            item['my_high_risk'] = this.set_high_risk(item.high_risk)
          })
          this.conData.data = data
        }
      } catch (error) {
        return false
      }
    },
    set_high_risk (val) {
      let val_name = '无'
      switch (val) {
        case 1:
          val_name = '利好'
          break;
        case 2:
          val_name = '警示'
          break;
        case 3:
          val_name = '提示'
          break;
        case 4:
          val_name = '高风险'
          break;

        default:
          break;
      }
      return val_name
    },
    // 展开主体查看详情
    async handleChange (id) {
      this.activeName = id;
      if (id) {
        this.conData.data = await []
      }
      if (this.activeName == id && id) {
        await this.getDailyDetailsCon(id)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-collapse {
  .el-collapse-item__header {
    font-size: 16px;
    font-weight: bold;
    color: #2974ef;
  }
}
::v-deep .el-tabs.el-tabs--card {
  .el-tabs__content {
    .el-button {
      white-space: pre-wrap;
    }
  }
}
</style>